import React, { useEffect, useRef, useState } from 'react'
import { useUserState } from 'contexts/UserContext'
import { Loader } from 'components/Loader'
import { CertificateItem } from './CertificateItem'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { useSelector } from 'lib/store'
import styled from 'styled-components/macro'
import { Inline, Stack } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import { certificateTypeColorMap, getCertificates } from './certificateHelpers'
import { CertificateListEmptyState } from './CertificateListEmptyState'
import { SelectBox, SelectBoxItem } from 'components/form'
import { CertificateRibbon } from './CertificateRibbon'
import { CertificateType, CertInventoryItem } from './certificateTypes'
import { CookieHunt } from 'features/cookie-hunt'
import { useLocation } from 'react-router-dom'
import { RootRef } from '@material-ui/core'
import { DrawingContestCertItem, getCertFromInventroy } from './DrawingCerts/CustomCertList'
import { useCertificateData } from './useCertificateData'
import { useHasAllCertificateContents } from './useHasAllCertificateContents'
import { getGrants } from 'lib/grants/grants'

interface CertificateListProps { }

export const CertificateList: React.FC<CertificateListProps> = () => {
    const { account, crm, grants } = useUserState()
    const fullName = crm.fullName ?? account.fullName
    const inventory = useSelector(state => state.client.inventory)
    const { data: CertData } = useCertificateData()
    const subjects = inventory?.filter(item => item.kind === 'certificate')
    const t = useTranslate()
    const [filter, setFilter] = useState<CertificateType | ''>('')
    const [isOpening, setOpening] = useState<boolean>(false)
    const gridRef = useRef<HTMLDivElement>(null)
    const { state } = useLocation<{ fromNotification: boolean | undefined }>()
    const hasAllCertContent: boolean = useHasAllCertificateContents()
    const certificateGrants = getGrants(grants)('content.certificates.subjects')

    useEffect(() => {
        if (subjects && fullName && state?.fromNotification && gridRef.current) {
            gridRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [subjects, fullName, state?.fromNotification, CertData?.data])

    // if (!subjects || !fullName || !CertData?.data) {     now skip !subjects check to avoid infinite loop
    if (!fullName || !CertData?.data) {
        return <Loader />
    }

    const certificates = getCertificates(
        subjects as CertInventoryItem[],
        CertData?.data as CertInventoryItem[],
        certificateGrants,
        account.id,
        hasAllCertContent,
        t,
        // inventory,
    )

    const drawingCerts = getCertFromInventroy(inventory, crm.lastSchool!)

    if (certificates.length === 0 && drawingCerts.length === 0) {
        return <CertificateListEmptyState />
    }

    const availableCertificateTypes = certificates.reduce<Set<CertificateType>>((acc, certificate) => {
        acc.add(certificate.subjectInfo.type)
        return acc
    }, new Set())

    const filteredCertificates = certificates.filter(certificate =>
        filter ? certificate.subjectInfo.type === filter : true,
    )

    if (drawingCerts.length !== 0) {
        availableCertificateTypes.add('event')
    }

    return (
        <Stack spacing={8}>
            {availableCertificateTypes.size > 1 && (
                <SelectBox
                    value={filter}
                    onChange={value => {
                        setFilter(value as CertificateType)
                    }}
                >
                    <SelectBoxItem value="">{t('general::all')}</SelectBoxItem>
                    {availableCertificateTypes.has('course') && (
                        <CategorySelect type="course">{t('certificates::category::course')}</CategorySelect>
                    )}
                    {availableCertificateTypes.has('camp') && (
                        <CategorySelect type="camp">{t('certificates::category::camp')}</CategorySelect>
                    )}
                    {availableCertificateTypes.has('workshop') && (
                        <CategorySelect type="workshop">{t('certificates::category::workshop')}</CategorySelect>
                    )}
                    {availableCertificateTypes.has('event') && (
                        <CategorySelect type="event">{t('certificates::category::special')}</CategorySelect>
                    )}
                </SelectBox>
            )}
            <RootRef rootRef={gridRef}>
                <Grid>
                    {filteredCertificates.map((certificate, idx) => (
                        <ErrorBoundary silent key={certificate.subjectInfo.key + certificate.subjectInfo.type + idx}>
                            <CookieHunt name="certificate-list" index={idx}>
                                <CertificateItem
                                    certificateInfo={certificate}
                                    fullName={fullName}
                                    isOpening={isOpening}
                                    setOpening={setOpening}
                                    userCountry={account.country}
                                />
                            </CookieHunt>
                        </ErrorBoundary>
                    ))}
                    {drawingCerts.length !== 0 &&
                        (filter === 'event' || filter === '') &&
                        drawingCerts.map((cert, i) => {
                            return (
                                <ErrorBoundary silent key={cert.certCode + i}>
                                    <DrawingContestCertItem
                                        cert={cert}
                                        fullName={fullName}
                                        isOpening={isOpening}
                                        setOpening={setOpening}
                                    />
                                </ErrorBoundary>
                            )
                        })}
                </Grid>
            </RootRef>
        </Stack>
    )
}

const CategorySelect: React.FC<{ type: CertificateType }> = ({ type, children }) => {
    return (
        <SelectBoxItem value={type}>
            <Inline display="flex" alignItems="center" spacing={2}>
                <StyledCertificateRibbon
                    color={certificateTypeColorMap[type].color}
                    highlightColor={certificateTypeColorMap[type].color}
                />
                <span>{children}</span>
            </Inline>
        </SelectBoxItem>
    )
}

const StyledCertificateRibbon = styled(CertificateRibbon)`
    width: 10px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: ${props => props.theme.spacing(6)};
`
