import { StoreState } from 'lib/store'
import { TypedTFunction } from 'lib/i18n/useTranslate'
import { filterNulls } from 'utils/arrayUtilts'
import { LocalisationKey } from 'lib/i18n'
import { lighten } from 'polished'
import {
    BlurbType,
    CertificateTranslations,
    CertificateType,
    CertInventoryItem,
    FinishedSubject,
    Subject,
    SubjectInfo,
} from './certificateTypes'
import { allSubject, certThumbnails } from './certificateData'
import { DateTimeFormat as PolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat'

const POLYFILL_LOCALES = ['bs'] // don't forget to import '@formatjs/intl-datetimeformat/locale-data/${locale} in _promise later on'
const OriginalDateTimeFormat = Intl.DateTimeFormat
const DynamicDateTimeFormat = new Proxy(PolyfillDateTimeFormat, {
    construct(target, args) {
        if (POLYFILL_LOCALES.includes(args[0])) {
            return new target(...args)
        } else {
            return OriginalDateTimeFormat(...args)
        }
    },
    get(target, prop) {
        if (prop === 'supportedLocalesOf') {
            return (value: string | string[], options?: Pick<Intl.DateTimeFormatOptions, 'localeMatcher'>) => {
                if (typeof value === 'string') {
                    if (POLYFILL_LOCALES.includes(value)) {
                        return target.supportedLocalesOf(value, options)
                    } else {
                        return OriginalDateTimeFormat.supportedLocalesOf(value, options)
                    }
                } else {
                    return value.map(locale => {
                        if (POLYFILL_LOCALES.includes(locale)) {
                            return target.supportedLocalesOf(locale, options)[0]
                        } else {
                            return OriginalDateTimeFormat.supportedLocalesOf(locale, options)[0]
                        }
                    })
                }
            }
        } else {
            // @ts-ignore
            return target[prop]
        }
    },
})

Object.defineProperty(Intl, 'DateTimeFormat', {
    value: DynamicDateTimeFormat,
    configurable: true,
    enumerable: false,
    writable: true,
})

const _promise = Promise.all([import('@formatjs/intl-datetimeformat/locale-data/bs')])

;(async () => {
    await _promise
})()

export const formatDate = (date: Date, language: string) => {
    return new Intl.DateTimeFormat(language, {
        month: 'long',
        year: 'numeric',
    }).format(date)
}

const DIGITAL_MISSION_2020_WINNER_ITEM_ID = '5f5763d3ce7a8e0009bd6074'

export const isDmWinner = (state: StoreState) => {
    if (state.client.inventory === null) {
        return undefined
    }
    return state.client.inventory.find(item => item.item === DIGITAL_MISSION_2020_WINNER_ITEM_ID)
}

export const getCertificateTranslations = (t: TypedTFunction, subjectInfo: Subject): CertificateTranslations => {
    const key = subjectInfo.nameKey || subjectInfo.key

    const blurbDict: Record<BlurbType, string> = {
        course: t(`certificate::blurb::general`, {
            course: t(`certificate::${key}::name` as LocalisationKey),
            defaultValue: null,
        }),
        camp: t(`certificate::blurb::camp`, {
            course: t(`certificate::${key}::name` as LocalisationKey),
            defaultValue: null,
        }),
        openday: t(`certificate::blurb::openday`, {
            course: t(`certificate::${key}::name` as LocalisationKey),
            defaultValue: null,
        }),
        digitalmission: t(`certificate::blurb::digitalmission`, {
            course: t(`certificate::${key}::name` as LocalisationKey),
            defaultValue: null,
        }),
        digitalmission2021: t(`certificate::blurb::digitalmission2021`, {
            course: t(`certificate::${key}::name` as LocalisationKey),
            defaultValue: null,
        }),
        digitalmission2022: t(`certificate::blurb::digitalmission2022`, {
            defaultValue: null,
        }),
        digitalmission2023: t(`certificate::blurb::digitalmission2023`, {
            defaultValue: null,
        }),
        scoolerMission: t(`certificate::blurb:ScoolerMission`, {
            defaultValue: null,
        }),
        workshop: t(`certificate::blurb::Workshops2021`, {
            course: t(`certificate::${key}::name` as LocalisationKey),
            defaultValue: null,
        }),
        campChallengeDay: t(`certificate::blurb::campChallengeDay`, {
            defaultValue: null,
        }),
    }
    const translations = {
        blurb: blurbDict[subjectInfo.blurbType],
        duringThisCourse: t('certificate::duringThisCourse'),
        duringThisWorkshop: t('certificate::duringThisWorkshop'),
        name: t(`certificate::${key}::name` as LocalisationKey /* , { defaultValue: null } */),
        thumbnail: (certThumbnails as any)[t(`certificate::${key}::thumbnail` as any as LocalisationKey)] || subjectInfo.thumbnail,
        items: t(`certificate::${key}::items` as LocalisationKey /* , { defaultValue: null } */),
    }

    return translations
}

export const downloadFile = (blob: Blob, fileName: string) => {
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', fileName)
    document.body.append(link)
    link.click()
    link.remove()
    window.addEventListener('focus', e => URL.revokeObjectURL(link.href), { once: true })
}

interface CertificateTypeColorScheme {
    color: string
    highlightColor: string
}

function createCertificateTypeColorScheme(color: string): CertificateTypeColorScheme {
    return {
        color: color,
        highlightColor: lighten(0.1, color),
    }
}

export const certificateTypeColorMap: Record<CertificateType, CertificateTypeColorScheme> = {
    course: createCertificateTypeColorScheme('#27389b'),
    camp: createCertificateTypeColorScheme('#2aa051'),
    event: createCertificateTypeColorScheme('#9c851e'),
    workshop: createCertificateTypeColorScheme('#673ab7'),
}

export const getCertificates = (
    userSubjects: CertInventoryItem[] = [],
    userCertificates: CertInventoryItem[] = [],
    certificateGrants: any[],
    userId: string,
    isSpecUserByGrant: boolean,
    t: TypedTFunction,
): SubjectInfo[] => {
    const SPECIAL_USER_IDS = [
        '57d6fc3a9235538e1171bf88', // guri
        // '5ece5e3826226f000a6ccadb', // barta.viktor
        '599a9492fa05e0e9db967a15', // sacperkb
        //'5bac8b4a02d65a439f6943ba', // sbalazs
        //'5ecd084726226f000a6cc9e1', // sallai.gabor
        '5e1c32777adab3001d420285', // nemes.aron
        //'57d866c89579f09904a2c0b6', // hunyi
    ]
    const isSpecialUser = isSpecUserByGrant || SPECIAL_USER_IDS.includes(userId)

    const allCourses = [...userSubjects, ...userCertificates]

    if (isSpecialUser) {
        allCourses.push(...allSubject.map(subject => createFakeInventoryItem(subject)))
    } else {
        allCourses.push(...subjectsFromCertificateGrants(certificateGrants).map(subject => createFakeInventoryItem(subject)))
    }

    const subjectToCertificate = createSubjectToCertificateFunction(t)
    const subjects = allCourses.map(subjectToCertificate).filter(filterNulls)
    return subjects
}

function createFakeInventoryItem(subject: Subject): CertInventoryItem {
    return {
        id: '60f512bc0b88d846d4aa9c8b',
        item: '',
        kind: 'subject',
        name: subject.key,
        catalog: '60b5ffd8d10358048804d52e',
        metadata: {
            subjectId: 'awdawdwdwadwadwawadw',
            subjectKey: subject.key,
            method: 'classroom',
            format: subject.type,
            schoolName: 'Teszt Iskola',
            schoolPortalName: 'Teszt Portal Iskola',
            date: '2021-07-19T05:50:52.073Z',
        },
        createdAt: new Date('2021-07-19T05:50:52.073Z'),
    }
}

// used for special/demo accounts to give certain certificates to users
function subjectsFromCertificateGrants(certificateGants: any[]): Subject[] {
    const subjects: Subject[] = [];
    certificateGants.forEach((grant: any) => {
        if (grant.data && Array.isArray(grant.data)) {
            grant.data.forEach((subject: any) => {
                if (typeof subject.key === 'string' && typeof subject.type === 'string') {
                    subjects.push(subject);
                }
            })
        }
    });
    return subjects;
}

export const createSubjectToCertificateFunction = (t: TypedTFunction) => (invItem: CertInventoryItem) => {
    const subjectInfo = getSubjectInfo(invItem.metadata.subjectKey, invItem.metadata?.format)
    if (!subjectInfo) {
        return null
    }

    const translations = getCertificateTranslations(t, subjectInfo)
    //Now this is a helper to find on the layout which cert has not correct translation key
    if (!translations.name || !translations.items) {
        console.log(`missing translation for ${subjectInfo.key}`)
        return null
    }

    const normalizedSubjectInfo: FinishedSubject = {
        ...subjectInfo,
        school_name: invItem.metadata.schoolName,
        school_portalName: invItem.metadata.schoolPortalName,
        lastDay: new Date(invItem.metadata.date),
    }

    return {
        subjectInfo: normalizedSubjectInfo,
        translations,
    }
}

export const getSubjectInfo = (subjecKey: string, subjectType: CertificateType): Subject | null => {
    try {
        const subject = allSubject.find(subject => subject.key === subjecKey && subject.type === subjectType)

        if (!subject) {
            return null
        }

        return subject
    } catch (e) {
        return null
    }
}

export const getSubjectNameKey = (subjecKey: string): string => {
    try {
        const subject = allSubject.find(subject => subject.key === subjecKey)

        if (!subject) {
            return subjecKey
        }

        return subject.nameKey || subject.key
    } catch (e) {
        return subjecKey
    }
}
